import React, { Component } from 'react'
import './App.css'

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import TermsOfUse from './components/TermsOfUse'
import Privacy from './components/Privacy'

class App extends Component<{},any> {
  async componentDidMount(): Promise<void> {
    const script = document.createElement('script');
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <React.StrictMode>
        <Router>
          <Navbar />
          <main className="main-content">
            <Routes>
              <Route index element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<TermsOfUse />} />
              <Route path="/privacy" element={<Privacy />} />
              {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
              <Route path="*" element={<Home />} />
            </Routes>
          </main>
        </Router>
      </React.StrictMode>
    )
  }
}

export default App
