import { Component } from 'react'
import Footer from './Footer'

const WEBSITE_NAME = "Lumosworks"

class About extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = { loaded: false }
  }

  componentDidMount(): void {
    this.setState({ loaded: true })
  }

  render() {
    return (
      <div className={this.state.loaded ? 'loaded' : ''}>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Catalog-Z About page</title>
        <link rel="stylesheet" href="css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" />
        <link rel="stylesheet" href="css/lumosworks.css" />
        {/* Page Loader */}
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
        <div
          className="tm-hero d-flex justify-content-center align-items-center"
          data-parallax="scroll"
          data-image-src="img/hero.jpg"
        />
        <div className="container-fluid tm-mt-60">
        <div style={styles.container}>
          <div className="row tm-mb-74 tm-row-1640">
            <div className="col-lg-5 col-md-6 col-12 mb-3">
              <img src="img/about.jpg" alt="Image" className="img-fluid" />
            </div>
            <div className="col-lg-7 col-md-6 col-12">
              <div className="tm-about-img-text">
                <p className="mb-4">
                  At {WEBSITE_NAME}, we make it easy for you to discover and connect with trusted businesses in your neighborhood.
                  Our platform allows you to search for local services and view their content directly on our website without needing to navigate away.
                  Whether you’re looking for a nearby beauty salon, nail services or hair stylists, you can explore their offerings and book appointments right from our platform.
                  With seamless browsing and integrated booking, we provide a smooth, secure, and efficient experience that keeps everything you need in one place.
                </p>
                {/* <p>
                  Credits go to Pexels and Unsplash for photos and video used in
                  this template. Catalog-Z is free{' '}
                  <a rel="sponsored" href="https://v5.getbootstrap.com/">
                    Bootstrap 5
                  </a>{' '}
                  Alpha 2 HTML Template designed for video and photo websites.
                </p>
                <p>
                  You are <b>allowed</b> to use this template for your
                  commercial or non-commercial websites. You can integrate it
                  with any kind of CMS website. You are <b>NOT allowed</b> to
                  redistribute the downloadable template ZIP file on any
                  template collection website. Please{' '}
                  <a
                    rel="nofollow"
                    href="https://templatemo.com/contact"
                    target="_parent"
                  >
                    contact us
                  </a>{' '}
                  for more information. Thank you.
                </p> */}
              </div>
            </div>
          </div>
          {/* <div className="row tm-mb-50">
            <div className="col-md-6 col-12">
              <div className="tm-about-2-col">
                <h2 className="tm-text-primary mb-4">
                  Left side of 2-Column content
                </h2>
                <p className="mb-4">
                  Pellentesque urna odio, scelerisque eu mauris vitae,
                  vestibulum sodales neque. Ut augue justo, tincidunt nec
                  aliquet ac, cursus vel augue. Suspendisse vel quam imperdiet,
                  sodales tellus sed, ullamcorper lorem.
                </p>
                <p>
                  Suspendisse id consequat risus. Aliquam varius posuere nunc,
                  nec imperdiet neque condimentum at. Aenean porta eleifend
                  venenatis. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="tm-about-2-col">
                <h2 className="tm-text-primary mb-4">
                  Right-side Title goes here
                </h2>
                <p className="mb-4">
                  Pellentesque urna odio, scelerisque eu mauris vitae,
                  vestibulum sodales neque. Ut augue justo, tincidunt nec
                  aliquet ac, cursus vel augue. Suspendisse vel quam imperdiet,
                  sodales tellus sed, ullamcorper lorem.
                </p>
                <p>
                  Suspendisse id consequat risus. Aliquam varius posuere nunc,
                  nec imperdiet neque condimentum at. Aenean porta eleifend
                  venenatis. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus.
                </p>
              </div>
            </div>
          </div>{' '} */}
          {/* row */}
          {/* <div className="row tm-mb-50">
            <div className="col-md-4 col-12">
              <div className="tm-about-3-col">
                <div className="tm-about-icon-container mb-5">
                  <i className="fas fa-desktop fa-3x tm-text-primary" />
                </div>
                <h2 className="tm-text-primary mb-4">Three-column title one</h2>
                <p className="mb-4">
                  Integer tristique arcu scelerisque mauris posuere convallis.
                  Fusce egestas ipsum sapien, hendrerit ultricies nisi viverra
                  eget. Vestibulum in tortor eget elit rutrum interdum.{' '}
                </p>
                <p>
                  Cras auctor velit urna, et feugiat ex tincidunt ut. Sed
                  viverra, elit at pulvinar tristique, sem quam vehicula dolor,
                  sed scelerisque augue mauris non dolor.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="tm-about-3-col">
                <div className="tm-about-icon-container mb-5">
                  <i className="fas fa-mobile-alt fa-3x tm-text-primary" />
                </div>
                <h2 className="tm-text-primary mb-4">
                  Title two of three-column
                </h2>
                <p className="tm-mb-50">
                  Donec nec est tincidunt, rhoncus nulla sit amet, imperdiet
                  augue. Phasellus sodales placerat ipsum ac auctor. Mauris
                  molestie blandit turpis. Mauris ante tellus, feugiat nec metus
                  non, bibendum semper velit.
                </p>
                <div className="text-center">
                  <a href="#" className="btn btn-primary">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="tm-about-3-col">
                <div className="tm-about-icon-container mb-5">
                  <i className="fas fa-photo-video fa-3x tm-text-primary" />
                </div>
                <h2 className="tm-text-primary mb-4">Third Title goes here</h2>
                <p className="mb-4">
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Donec nec est tincidunt,
                  rhoncus nulla sit amet, imperdiet augue.{' '}
                </p>
                <p>
                  Phasellus sodales placerat ipsum ac auctor. Mauris molestie
                  blandit turpis. Mauris ante tellus, feugiat nec metus non,
                  bibendum semper velit.
                </p>
              </div>
            </div>
          </div> */}
          </div>{' '}
        </div>
        <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
          <Footer />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  title: {
    textAlign: "center" as const,
  },
}

export default About
