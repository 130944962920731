import { Component } from "react"
import Footer from "./Footer"

const WEBSITE_NAME = "Lumosworks"

class Privacy extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = { loaded: false }
  }

  componentDidMount(): void {
    this.setState({ loaded: true })
  }

  render() {
    return (
      <div className={this.state.loaded ? 'loaded' : ''}>
        <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Catalog-Z About page</title>
          <link rel="stylesheet" href="css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" />
          <link rel="stylesheet" href="css/lumosworks.css" />
          {/* Page Loader */}
          <div id="loader-wrapper">
            <div id="loader" />
            <div className="loader-section section-left" />
            <div className="loader-section section-right" />
          </div>
          <div
            className="tm-hero d-flex justify-content-center align-items-center"
            data-parallax="scroll"
            data-image-src="img/hero.jpg"
          />

        <div style={styles.container}>
          <h1 style={styles.title}>Privacy Policy</h1>
          <p><em>Last Updated: 10/22/2024</em></p>

          <p>
            <strong>{WEBSITE_NAME}</strong> ("we", "us", or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you visit our website <strong>lumosworks.com</strong> (the "Website").
          </p>

          <p>
            By using the Website, you agree to the collection and use of information in accordance with this policy. If you do not agree with this policy, please do not use the Website.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            We only collect <strong>anonymous data</strong> that does not identify you personally. This includes:
          </p>
          <ul>
            <li>Click data that helps us understand how users navigate the Website.</li>
            <li>Aggregate statistics on how often links are clicked.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>
            We use the anonymous data we collect for the following purposes:
          </p>
          <ul>
            <li>To improve the performance and functionality of the Website.</li>
            <li>To analyze user behavior and trends on the Website.</li>
          </ul>

          <h2>3. Sharing Your Information</h2>
          <p>
            Since we collect only anonymous data, we do not share any personally identifiable information with third parties. However, we may share aggregated data with service providers or partners to help improve the Website’s performance or functionality.
          </p>

          <h2>4. Cookies</h2>
          <p>
            The Website may use cookies to collect anonymous data and enhance your browsing experience. Cookies are small files stored on your device that help us understand user interactions with the Website.
          </p>
          <p>
            You can choose to disable cookies through your browser settings, but doing so may limit your ability to use some features of the Website.
          </p>

          <h2>5. Third-Party Links</h2>
          <p>
            The Website may contain links to third-party websites. We are not responsible for the privacy practices or content of such external sites. We encourage you to review the privacy policies of any third-party websites you visit.
          </p>

          <h2>6. Data Security</h2>
          <p>
            We take reasonable measures to protect the anonymous data collected from unauthorized access, disclosure, or alteration. However, no method of transmission over the Internet is completely secure, and we cannot guarantee the absolute security of your data.
          </p>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>
            We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date. Your continued use of the Website after such changes constitutes your acceptance of the new Privacy Policy.
          </p>

          <h2>8. Contact Information</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@lumosworks.com" target="_blank">info@lumosworks.com</a>.
          </p>
        </div>        
        <Footer />
      </div>
    )
  }
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  title: {
    textAlign: "center" as const,
  },
}

export default Privacy
