import { Component } from "react";
import { Link } from 'react-router-dom';

class Footer extends Component<{},any> {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <footer className="tm-bg-gray pt-5 pb-3 tm-text-gray tm-footer">
        <div className="container-fluid tm-container-small">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 px-5 mb-5">
              <h3 className="tm-text-primary mb-4 tm-footer-title">
                About Lumosworks
              </h3>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 px-5 mb-5">
              {/* <ul className="tm-social-links d-flex justify-content-end pl-0 mb-5">
                <li className="mb-2">
                  <a href="https://facebook.com">
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://twitter.com">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://instagram.com">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://pinterest.com">
                    <i className="fab fa-pinterest" />
                  </a>
                </li>
              </ul> */}
              <Link  className="tm-text-gray text-right d-block mb-2" to="/terms">
                Terms of Use
              </Link>
              <Link  className="tm-text-gray text-right d-block mb-2" to="/privacy">
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-7 col-12 px-5 mb-3">
              Copyright Lumosworks. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;

