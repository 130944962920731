import { useState } from "react";
import { NavLink } from "react-router-dom";
import "../css//Navbar.css";


const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  
  return (
    <header className="header">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <i className="fas fa-film mr-2" />
              Lumosworks
            </a>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <i className="fas fa-bars"></i>
            </button>
            <div className={"navbar-collapse " + (menuOpen ? "" : "collapse")} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/home" onClick={toggleMenu}>Home</NavLink>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link nav-link-1 active"
                    aria-current="page"
                    href="index.html"
                  >
                    Home
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a className="nav-link nav-link-2" href="videos.html">
                    Videos
                  </a>
                </li> */}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about" onClick={toggleMenu}>About Us</NavLink>
                  </li>
                {/* <li className="nav-item">
                  <Link className="nav-link nav-link-3" to="/contact">
                    Contact
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
   </header>
 );
};

export default Navbar;