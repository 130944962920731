import { Component } from 'react'

import Popup from 'reactjs-popup'
import { ResponsiveIframeViewer, ViewportSize } from 'react-responsive-iframe-viewer'
import {isMobile} from 'react-device-detect'
import StarRating from "./StarRating"
import Footer from './Footer'

const FETCH_SITE_DATA_URL = "https://cotqfxogqdpvs5qlj57d5r25wa0whjhu.lambda-url.us-east-1.on.aws/";

const itemsPerPage = 18
class Home extends Component<{},any> {
  totalPages = 0
  currentImages = []
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      popup: false,
      currentPage: 1,
      currentImages: []
    };
  }

  async componentDidMount(): Promise<void> {
    const response = await fetch(FETCH_SITE_DATA_URL)
    const siteData = await response.json()
    // for (let i = 0; i < 5; i++) {
    //   siteData.push(...siteData)
    // }
    this.setState({siteData: siteData})
    this.setState({loaded: true})

    // Calculate total pages
    if (siteData) {
      this.totalPages = Math.ceil(siteData.length / itemsPerPage);
      this.setCurrentImages(siteData, this.state.currentPage)
    }
  }
  
  showPopup = (url: string) => {
    if (this.state.popup === false) {
      this.setState({popupUrl: url})
      this.setPopup(true)
    }
  }

  setPopup = (state: boolean) => {
    this.setState({popup: state})
  }

  setCurrentImages = (siteData: any[], currentPage: number) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    this.setState({currentImages: siteData.slice(startIndex, startIndex + itemsPerPage)})
  }

  // Handle pagination click
  handlePrevPage = () => {
    if (this.atFirstPage()) {
      return
    }
    const currentPage = Math.max(this.state.currentPage - 1, 1)
    this.setState({currentPage: currentPage})
    this.setCurrentImages(this.state.siteData, currentPage)
  }

  handleNextPage = () => {
    if (this.atLastPage()) {
      return
    }
    const currentPage = Math.min(this.state.currentPage + 1, this.totalPages)
    this.setState({currentPage: currentPage})
    this.setCurrentImages(this.state.siteData, currentPage)
  }

  atFirstPage = () => {
    return this.state.currentPage === 1
  }

  atCurrentPage = (pageIndex: number) => {
    return this.state.atCurrentPage === pageIndex
  }

  atLastPage = () => {
    return this.state.currentPage === this.totalPages
  }

  setCurrentPage = (pageIndex: number) => {
    this.setState({currentPage: pageIndex})
    this.setCurrentImages(this.state.siteData, pageIndex)
  }

  renderPageLinks = () => {
    let pageLinks = []
    for (let pageIndex = 1; pageIndex <= this.totalPages; pageIndex++) {
      pageLinks.push(<a href="#" className={`tm-paging-link ${pageIndex === this.state.currentPage && 'active'}`} onClick={() => this.setCurrentPage(pageIndex)}>{pageIndex}</a>)
    }
    return pageLinks
  }

  render() {
    return (
      <div className={this.state.loaded ? "loaded" : ""}>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Lumosworks</title>
        <link rel="stylesheet" href="css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" />
        <link rel="stylesheet" href="css/lumosworks.css" />
        {/*
    
        {/* Page Loader */}
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
        <div
          className="tm-hero d-flex justify-content-center align-items-center"
          data-parallax="scroll"
          data-image-src="img/hero.jpg"
        >
          {/* <div className="icon">
            <a href="#">
              <img src="/img/haircut.png" alt="Hair Cut" />
            </a>
          </div> */}
          {/* <form className="d-flex tm-search-form">
            <input
              className="form-control tm-search-input"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button
              className="btn btn-outline-success tm-search-btn"
              type="submit"
            >
              <i className="fas fa-search" />
            </button>
          </form> */}
        </div>
        <div className="container-fluid tm-container-content tm-mt-60">
          {/* <div className="row mb-4">
            <h2 className="col-6 tm-text-primary"></h2>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <form className="tm-text-primary">
                Page{' '}
                <input
                  type="text"
                  value={this.state.currentPage}
                  size={1}
                  className="tm-input-paging tm-text-primary"
                />{' '}
                of {this.totalPages}
              </form>  
            </div>
          </div> */}
          <Popup 
            open={this.state.popup}
            modal
            closeOnDocumentClick
            onClose={() => this.setPopup(false)}
          >
            <div style={{border: '1mm ridge #009999'}}>
              <button style={{position: 'absolute', right: 0}} onClick={() => this.setPopup(false)}>X</button>
              {
              <ResponsiveIframeViewer
                  src={this.state.popupUrl}
                  title=''
                  showControls={false}
                  size={isMobile ? ViewportSize.mobile : ViewportSize.desktop}
              />
              }
            </div>
          </Popup>
          <div className="row tm-mb-90 tm-gallery">
            {
              this.state.currentImages && this.state.currentImages.map((data: any, index: number) => {
                const url = `https://lumos-sites.s3.amazonaws.com/protected/${data.site_uuid}/site/site-thumbnail.jpg`
                return (
                  <div key={`site-${index}`} className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                    <figure className="effect-ming tm-video-item">
                    <img src={`${url}`} alt="Image" className="img-fluid" />
                      <figcaption className="d-flex align-items-center justify-content-center" onClick={() => this.showPopup(`https://${data.subdomain}.lumosworks.com`)}>
                          <h2>{data.business_name}</h2>
                      </figcaption>
                    </figure>
                    <div className="d-flex justify-content-between tm-text-gray">
                      <span className="tm-text-gray-light">
                        <StarRating />
                      </span>
                      <span>{Math.floor(Math.random() * 1000)} views</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* roxw */}
          <div className="row tm-mb-90">
            <div className="col-12 d-flex justify-content-between align-items-center tm-paging-col">
              <a
                className={`btn btn-primary tm-btn-prev mb-2 ${this.atFirstPage() && 'disabled'}`}
                onClick={this.handlePrevPage}
              >
                Previous
              </a>
              <div className="tm-paging d-flex">
                { this.renderPageLinks() }
              </div>
              <a
                className={`btn btn-primary tm-btn-prev mb-2 ${this.atLastPage() && 'disabled'}`}
                onClick={this.handleNextPage}
              >
                Next Page
              </a>
            </div>
          </div>
        </div>
        {/* container-fluid, tm-container-content */}
        <Footer />
      </div>
    )
  }
}

export default Home
