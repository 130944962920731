import React, { Component } from "react";
import "../css//StarRating.css"; // Import the CSS file

class StarRating extends Component<{},any> {
  constructor(props: {}) {
    super(props);
    this.state = {
      rating: 0,
      hover: 0,
    };
  }

  // Handle click event to set rating
  handleClick = (index: number) => {
    this.setState({ rating: index });
  };

  // Handle mouse enter event to show hovered stars
  handleMouseEnter = (index: number) => {
    this.setState({ hover: index });
  };

  // Handle mouse leave event to reset hover state
  handleMouseLeave = () => {
    this.setState({ hover: this.state.rating });
  };

  render() {
    return (
      <div className="stars">
        {[...Array(5)].map((star, index) => {
          index += 1; // Index starts from 1 instead of 0
          return (
            <span
              key={index}
              className={index <= (this.state.hover || this.state.rating) ? "star filled" : "star hollow"}
              onClick={() => this.handleClick(index)} // Set rating on click
              onMouseEnter={() => this.handleMouseEnter(index)} // Fill stars on hover
              onMouseLeave={this.handleMouseLeave} // Reset stars on hover leave
            >
              &#9733; {/* Star character */}
            </span>
          );
        })}
      </div>
    );
  }
}

export default StarRating;
