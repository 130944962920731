import { Component } from "react";
import Footer from "./Footer"

const WEBSITE_NAME = "Lumosworks"


class TermsOfUse extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = { loaded: false }
  }

  componentDidMount(): void {
    this.setState({ loaded: true })
  }

  render() {
    return (
      <div className={this.state.loaded ? 'loaded' : ''}>
        <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Catalog-Z About page</title>
          <link rel="stylesheet" href="css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" />
          <link rel="stylesheet" href="css/lumosworks.css" />
          {/* Page Loader */}
          <div id="loader-wrapper">
            <div id="loader" />
            <div className="loader-section section-left" />
            <div className="loader-section section-right" />
          </div>
          <div
            className="tm-hero d-flex justify-content-center align-items-center"
            data-parallax="scroll"
            data-image-src="img/hero.jpg"
          />

        <div style={styles.container}>
          <h1 style={styles.title}>Terms of Use</h1>
          <p>
            <em>Last Updated: 10/22/2024</em>
          </p>

          <p>
            Welcome to <strong>{WEBSITE_NAME}</strong> (the "Website"). By using this
            Website, you agree to comply with and be bound by the following Terms of
            Use ("Terms"). If you do not agree to these Terms, you should not use
            the Website.
          </p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using the Website, you agree to be bound by these Terms
            of Use and our{" "}
            <a href="/privacy">Privacy Policy</a>. These Terms apply to
            all visitors, users, and others who access or use the Website.
          </p>

          <h2>2. Use of the Website</h2>
          <p>
            The Website provides users with links to third-party websites and
            collects anonymous click data for analytical purposes. You agree to use
            the Website in accordance with these Terms and any applicable laws.
          </p>

          <h2>3. Collection of Anonymous Data</h2>
          <p>
            We collect click data from users visiting the Website. This data is
            entirely anonymous and does not contain any personally identifiable
            information. The data is collected for analytical purposes to help
            improve the Website and user experience.
          </p>

          <h2>4. Third-Party Links</h2>
          <p>
            The Website may contain links to third-party websites that are not owned
            or controlled by <strong>{WEBSITE_NAME}</strong>. We are not responsible
            for the content, privacy policies, or practices of any third-party
            websites. You acknowledge and agree that <strong>{WEBSITE_NAME}</strong>{" "}
            is not liable for any damages or losses caused by or in connection with
            your use of such websites.
          </p>

          <h2>5. Prohibited Conduct</h2>
          <p>When using the Website, you agree not to:</p>
          <ul>
            <li>Violate any laws or regulations.</li>
            <li>Use the Website for any unlawful purpose.</li>
            <li>
              Interfere with the operation of the Website or any other user’s
              enjoyment of the Website.
            </li>
            <li>Attempt to access the Website’s underlying systems without authorization.</li>
          </ul>

          <h2>6. Intellectual Property</h2>
          <p>
            All content, features, and functionality on the Website, including but
            not limited to text, graphics, logos, and software, are the exclusive
            property of <strong>{WEBSITE_NAME}</strong> and are protected by
            intellectual property laws. You may not copy, reproduce, or distribute
            any content from the Website without prior written permission from{" "}
            <strong>{WEBSITE_NAME}</strong>.
          </p>

          <h2>7. Disclaimer</h2>
          <p>
            The Website is provided on an "as-is" and "as-available" basis without
            warranties of any kind. <strong>{WEBSITE_NAME}</strong> does not
            guarantee that the Website will be error-free or uninterrupted, nor do
            we warrant the accuracy or reliability of the content or information
            available through the Website.
          </p>

          <h2>8. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, <strong>{WEBSITE_NAME}</strong>{" "}
            shall not be liable for any damages arising out of or in connection with
            the use of the Website or third-party links.
          </p>

          <h2>9. Changes to Terms</h2>
          <p>
            We reserve the right to modify these Terms at any time. Any changes will
            be posted on this page, and your continued use of the Website after such
            changes constitutes your acceptance of the new Terms.
          </p>

          <h2>10. Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the
            laws of state of Delware. Any legal action or proceeding arising
            under these Terms will be brought exclusively in the courts located in
            state of Delware.
          </p>

          <h2>11. Contact Information</h2>
          <p>
            If you have any questions about this Terms of Service, please contact us at <a href="mailto:info@lumosworks.com" target="_blank">info@lumosworks.com</a>.
          </p>
        </div>
        <Footer />
      </div>
    )
  }
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  title: {
    textAlign: "center" as const,
  },
}

export default TermsOfUse
